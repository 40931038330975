import { BrowserRouter } from "react-router-dom";
import {
  Contact,
  Experience,
  Hero,
  About,
  Navbar,
  Works,
  Footer,
  WhatsApp,
} from "./components";
import "./App.css";
import React from "react";

function App() {
  return (
    <BrowserRouter>
      <div style={{ position: "relative", zIndex: 0 }}>
        <div className="appContainer">
          <Navbar />
          <Hero />
          <About />
          <Experience />
          <Works />
          <div style={{ position: "relative", zIndex: 0 }}>
            <Contact />
          </div>
          <Footer />
        </div>
        <WhatsApp />
      </div>
    </BrowserRouter>
  );
}

export default App;
