import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import localisationicon from "../../assets/svgassets/location-svgrepo-com (1).svg";
import phoneicon from "../../assets/svgassets/phone-out-svgrepo-com.svg";
import emailicon from "../../assets/svgassets/email-message-mail-envelope-svgrepo-com.svg";

import SectionWrapper from "../../scrollMotion/SectionWrapper";

import "./Contact.css";
import Social from "../Socialmedia/Social";
const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    from_name: "",
    user_email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send("service_vkz3545", "template_ruu17uu", form, "-LGxwq7XHR9CtUKEb")
      .then(
        () => {
          setLoading(false);
          alert("Merci. Je vous répondrai dans les plus brefs délais.");
          // toast.success("Merci. Je vous répondrai dans les plus brefs délais.");
          setForm({
            from_name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Ahh, quelque chose s'est mal passé. Veuillez réessayer.");
          // toast.error(
          //   "Ahh, quelque chose s'est mal passé. Veuillez réessayer."
          // );
        }
      );
  };

  return (
    <div className="contact">
      <div
        className="contactform"
        style={{
          padding: "2rem",
          borderRadius: "8px",
          backgroundColor: "rgb(229 229 229)",
          width: "100%",
          flex: 2,
        }}
      >
        <h3 className="sectionHeadText">Contactez Nous</h3>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            marginTop: "3rem",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <div className="name_email">
            <label
              className="labels_container"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
            >
              <span className="inputs_labels">Votre nom</span>
              <input
                type="text"
                name="from_name"
                value={form.from_name}
                onChange={handleChange}
                placeholder="Entrer Votre Nom"
                className="inputs_contact"
              />
            </label>
            <label
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
              className="labels_container"
            >
              <span className="inputs_labels">email</span>
              <input
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                placeholder="Entrer Votre Adresse Mail"
                className="inputs_contact"
              />
            </label>
          </div>
          <label style={{ display: "flex", flexDirection: "column" }}>
            <span className="inputs_labels">Message</span>
            <textarea
              rows={7}
              name="message"
              value={form.message}
              onChange={handleChange}
              placeholder="Entrer Votre Message ici"
              className="inputs_contact"
            />
          </label>

          <button type="submit" className="submit_btn">
            {loading ? "Encore..." : "Envoyer"}
          </button>
        </form>
      </div>
      <div className="address">
        <h3>Adresse</h3>
        <div className="addr local">
          <img src={localisationicon} alt="localisation" />
          <p>Hay Mohamadi ,Agadir ,Maroc</p>
        </div>
        <div className="addr phone">
          <img src={phoneicon} alt="telephone" />
          <p>(+212) 600-651985</p>
        </div>
        <div className="addr mail">
          <img src={emailicon} alt="email" />
          <p>fztechsecure@gmail.com</p>
        </div>
        <h4>Suivez nous :</h4>
        <Social />
      </div>
    </div>
  );
};

export default SectionWrapper(Contact, "contact");
