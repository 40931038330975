import {
  camera,
  alarm,
  network,
  telephone,
  incendie,
  controle,
  alarmicon,
  cameraicon,
  incendieicon,
  porteicon,
  satelliteicon,
  neticon,
  controleicon,
  telephoneicon,
  sonorisation,
  fibre,
  domotique,
  tableInteractif,
  alarmAntiantrusion,
  autoPortes,
  sonorisationIcon,
  fibreIcon,
  tableauIcon,
  domotiqueIcon,
} from "../assets";
export const navLinks = [
  {
    id: "about",
    title: "Apropos",
  },
  {
    id: "work",
    title: "Service",
  },

  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "Caméras de surveillance",
    icon: camera,
  },
  {
    title: "Alarme incendie",
    icon: alarm,
  },
  {
    title: "Détection incendie",
    icon: incendie,
  },
  {
    title: "Sonorisation",
    icon: sonorisation,
  },
  {
    title: "Réseaux informatiques",
    icon: network,
  },
  {
    title: "Alarme anti intrusion",
    icon: alarmAntiantrusion,
  },
  {
    title: "Contrôle d'accès",
    icon: controle,
  },
  {
    title: "Standard téléphonique",
    icon: telephone,
  },
  {
    title: "Fibre optique",
    icon: fibre,
  },
  {
    title: "Domotique",
    icon: domotique,
  },
  {
    title: "Tableaux interactifs",
    icon: tableInteractif,
  },
  {
    title: "Portes automatiques",
    icon: autoPortes,
  },
];

const experiences = [
  {
    title: "Alarme anti intrusion",
    company_name: "______________",
    icon: alarmicon,
    iconBg: "#383E56",
    date: "",
    points: [
      "Gérez votre système d'alarme où que vous soyez grâce à notre accès à distance, garantissant un contrôle total de votre sécurité.",
      "Profitez d'une surveillance intelligente avec des capteurs sophistiqués qui distinguent les menaces réelles des fausses alarmes.",
      "Choisissez entre des alarmes silencieuses ou audibles, adaptées à vos préférences et aux besoins spécifiques de votre situation.",
      "Détectez les intrusions dès le début avec notre système de détection précoce, garantissant une réponse rapide en cas de menace.",
    ],
  },
  {
    title: "Caméras de surveillance",
    company_name: "______________",
    icon: cameraicon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Profitez d'une tranquillité d'esprit totale grâce à notre système de surveillance 24/7, surveillant votre propriété en temps réel.",
      "Nos caméras haute définition capturent chaque détail, offrant une surveillance claire et précise.",
      "Surveillez votre propriété de n'importe où grâce à notre accès à distance, via une application conviviale sur votre smartphone.",
      "Réduisez les faux alarmes avec nos caméras dotées de systèmes de détection de mouvement avancés.",
    ],
  },
  {
    title: "Réseaux informatiques ",
    company_name: "______________",
    icon: neticon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Profitez de notre expertise dans la conception et l'installation de réseaux informatiques fiables, adaptés à vos besoins spécifiques.",
      "Optimisez votre connectivité avec nos solutions de réseaux sans fil performantes, offrant une connectivité rapide et stable.",
      "Assurez-vous du bon fonctionnement de votre réseau grâce à notre service de maintenance et de support continus, vous permettant de rester concentré sur votre activité.",
      "Identifiez les goulots d'étranglement et optimisez l'utilisation de votre bande passante grâce à nos services d'analyse spécialisés.",
    ],
  },
  {
    title: "Détection incendie",
    company_name: "______________",
    icon: incendieicon,
    iconBg: "#383E56",
    date: "",
    points: [
      "Protégez votre propriété avec nos systèmes de détection incendie avancés, offrant une réponse rapide en cas de menace de feu.",
      "Nos capteurs sensibles à la fumée et à la chaleur assurent une détection précoce, minimisant les risques d'incendie.",
      "Maximisez votre sécurité en intégrant nos systèmes de détection incendie avec nos alarmes anti-intrusion, pour une protection complète.",
      "Optez pour une sécurité accrue avec nos systèmes automatiques d'extinction, qui peuvent minimiser les dégâts en cas d'incendie.",
    ],
  },
  {
    title: "Contrôle d'accès",
    company_name: "______________",
    icon: controleicon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Optez pour nos solutions de contrôle d'accès avancées, offrant une sécurité optimale pour votre entreprise.",
      "Simplifiez la gestion des accès avec nos systèmes de badge et cartes magnétiques, assurant une identification rapide et sécurisée.",
      "Garantissez une sécurité maximale avec nos systèmes de contrôle d'accès basés sur la biométrie, assurant une identification unique et fiable.",
      "Facilitez la gestion de la sécurité avec notre système de contrôle d'accès centralisé, offrant un contrôle complet sur toutes les entrées.",
    ],
  },
  {
    title: "Portes automatiques",
    company_name: "______________",
    icon: porteicon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Transformez votre espace avec nos portes automatiques innovantes, offrant praticité et modernité.",
      "Optez pour l'élégance et la fonctionnalité avec nos portes coulissantes automatiques, parfaites pour un accès fluide.",
      "Simplifiez l'accès avec nos portes battantes automatiques, combinant confort et sécurité.",
      "Intégrez le contrôle d'accès à vos portes automatiques pour une sécurité accrue et une gestion facilitée.",
    ],
  },
  {
    title: "Standard téléphonique",
    company_name: "______________",
    icon: telephoneicon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Optimisez votre communication avec notre service de standard téléphonique professionnel, garantissant une gestion efficace des appels.",
      "Maximisez la productivité avec notre système de routage intelligent des appels, assurant une distribution rapide et efficace.",
      "Impressionnez vos appelants avec notre messagerie vocale personnalisée, offrant une expérience professionnelle dès le premier contact.",
      "Profitez de la flexibilité de la VoIP avec notre intégration transparente, offrant une communication claire et économique.",
    ],
  },
  {
    title: "Système de satellites",
    company_name: "______________",
    icon: satelliteicon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Offrez-vous une connectivité mondiale fiable grâce à notre système de satellites, assurant une communication sans faille où que vous soyez.",
      "Explorez de nouveaux horizons avec nos systèmes de satellites dédiés à la surveillance et à la télédétection, offrant des données précises et en temps réel.",
      "Soyez prêt pour l'inattendu avec notre service de communication d'urgence par satellite, assurant une liaison vitale même dans les situations les plus critiques.",
      "Explorez le détail avec nos services d'imagerie satellitaire de haute résolution, fournissant des informations visuelles précises pour une prise de décision éclairée.",
    ],
  },
  {
    title: "Sonorisation",
    company_name: "______________",
    icon: sonorisationIcon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Transformez votre événement avec notre expertise en sonorisation professionnelle, assurant une expérience auditive exceptionnelle.",
      "Optez pour la qualité avec nos équipements audio de pointe, offrant une reproduction sonore claire et immersive.",
      "Notre équipe assure une installation et une configuration sur mesure, adaptées à la nature unique de votre espace.",
      "Faites vibrer votre public avec notre service de sonorisation pour événements en direct, assurant une qualité sonore inégalée.",
    ],
  },
  {
    title: "Fibre optique",
    company_name: "______________",
    icon: fibreIcon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Expérimentez une vitesse inégalée avec notre service de fibre optique, offrant une connexion internet ultra-rapide pour répondre à vos besoins les plus exigeants.",
      "Profitez d'une installation professionnelle et rapide de notre service de fibre optique, minimisant les interruptions et vous offrant une connectivité sans compromis.",
      "Libérez le potentiel de votre entreprise avec notre service de fibre optique, permettant un transfert de données à haute capacité pour une efficacité maximale.",
      "Notre équipe de service clientèle dédiée est là pour vous offrir un soutien personnalisé à chaque étape, assurant une expérience sans tracas avec notre service de fibre optique.",
    ],
  },
  {
    title: "Tableaux Interactifs",
    company_name: "______________",
    icon: tableauIcon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Transformez votre salle de réunion en un espace de collaboration numérique avancé avec nos tableaux interactifs, favorisant l'interaction et la créativité.",
      "Profitez d'une installation professionnelle par notre équipe d'experts techniques, assurant une configuration optimale de vos tableaux interactifs.",
      "Avant la finalisation de l'installation, nous effectuons des tests et des vérifications approfondis pour garantir le bon fonctionnement et la fiabilité de vos tableaux interactifs.",
      "Votre satisfaction est notre priorité absolue. Nous nous engageons à fournir une installation de tableaux interactifs de qualité supérieure, répondant à vos attentes et dépassant vos exigences.",
    ],
  },
  {
    title: "Domotique",
    company_name: "______________",
    icon: domotiqueIcon,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "Transformez votre maison en un espace intelligent avec notre service de domotique, offrant une automatisation avancée pour simplifier votre vie quotidienne.",
      "Protégez votre famille et votre propriété avec nos solutions de domotique, offrant une sécurité avancée et une surveillance en temps réel pour une tranquillité d'esprit totale.",
      "Notre équipe assure une installation professionnelle de votre système de domotique, suivie d'un support continu pour garantir un fonctionnement optimal à long terme.",
      "Prenez le contrôle total de votre maison avec notre système de domotique, vous permettant de gérer tous vos équipements depuis un seul endroit, que ce soit chez vous ou à distance.",
    ],
  },
];

export { services, experiences };
