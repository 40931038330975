import React from "react";
import logo from "../../assets/png/fz_tech_vertical.png";
import "./Footer.css";
import Social from "../Socialmedia/Social";

const Footer = () => (
  <footer className="page-footer font-small blue pt-4">
    <div className="container-fluid text-center text-md-left">
      <div className="row">
        <div className="col-md-6 mt-md-0 mt-3">
          <a href="https://fztechsecure.ma/" rel="noopener noreferrer">
            <img style={{ width: "190px" }} src={logo} alt="fztechsecure.ma" />
          </a>
          <p className="footer-desc">
            Construisez votre système de sécurité sur mesure avec nos solutions
            évolutives, adaptées à vos besoins changeants.
          </p>
        </div>

        <hr className="clearfix w-100 d-md-none pb-0" />

        <div className="col-md-3 mb-md-0 mb-3 footer-links">
          <h5 className="text-uppercase footer-link">Liens</h5>
          <ul className="list-unstyled">
            <li>
              <a href="#About">A propos de nous </a>
            </li>
            <li>
              <a href="#Experience">notre travaille</a>
            </li>
            <li>
              <a href="#Contact">contactez-nous</a>
            </li>
            <li
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                color: "white",
                left: "0",
                fontWeight: "bold",
                fontSize: "17px",
              }}
            >
              {" "}
              Notre Réseaux Sociaux:
            </li>
            <li className="socialicons">
              <Social />
            </li>
          </ul>
        </div>
        <div className="col-md-3 mb-md-0 mb-3 footer-links">
          <h5 className="text-uppercase footer-link">Services</h5>
          <ul className="list-unstyled">
            <li>
              <a href="#contact">Caméras de surveillance</a>
            </li>
            <li>
              <a href="#contact"> Alarme anti intrusion</a>
            </li>
            <li>
              <a href="#contact">Détection incendie</a>
            </li>
            <li>
              <a href="#contact">Réseaux informatiques</a>
            </li>
            <li>
              <a href="#contact">Contrôle d'accès</a>
            </li>
            <li>
              <a href="#contact">Portes automatiques</a>
            </li>
            <li>
              <a href="#contact">Standard téléphonique</a>
            </li>
            <li>
              <a href="#contact">Système de satellites</a>
            </li>
            <li>
              <a href="#contact">Sonorisation</a>
            </li>
            <li>
              <a href="#contact">Fibre optique</a>
            </li>
            <li>
              <a href="#contact">Domotique</a>
            </li>
            <li>
              <a href="#contact">Tableaux interactifs</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      className="footer-copyright f-copyright text-center py-3 "
      style={{ color: "white", background: "black" }}
    >
      © 2024 Copyright :<a href=" https://fztechsecure.ma/"> fztechsecure.ma</a>
    </div>
  </footer>
);

export default Footer;
