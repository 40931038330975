import React from "react";
import { services } from "../../constants";
import "./About.css";
import SectionWrapper from "../../scrollMotion/SectionWrapper";

const ServiceCard = ({ index, title, icon }) => {
  return (
    <div className="serviceCardContainer">
      <div className="serviceCard">
        <div
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="serviceCardContent"
          style={{
            backgroundImage:
              "linear-gradient(rgb(0 0 0 / 44%), rgb(0 0 0 / 64%)), url(" +
              icon +
              ")",
            backgroundSize: "cover",
          }}
        >
          {/* <img src={icon} alt={title} className="serviceIcon" /> */}
          <h3 className="serviceTitle">{title}</h3>
        </div>
      </div>
    </div>
  );
};

const About = () => {
  return (
    <>
      <div style={{ textAlign: "center", margin: "48px auto" }}></div>
      {/*  */}
      <p className="description">
        <span className="company-name">FZ TECH SECURE SARL </span>offre des
        solutions compétitives et convenables en domaine de sécurité et sécurité
        électronique et depuis dizaine d’années qui a lui permet de proposer une
        meilleure gamme de produits à sa clientèle et lui faire profiter des
        dernières technologies connus. Gérer par une jeune équipe hautement
        qualifiée et disposant d’une large expérience dans le domaine de réseau
        informatique et de la sécurité électronique tel que : Domotique &
        Automatisme Réseau & Télécommunication Système de pointage & Contrôle
        d’accès Système d’intrusion & incendie Télésurveillance &
        Vidéosurveillance Matériel informatique & Bureautique Câblage &
        Accessoires Sonorisation & télédistribution Etude, Conseil & Vente
        L’esprit de professionnalisme et de créativité permet à FZ TECH SECURE
        de centralisé ses efforts pour accroitre ses relations commerciales et
        technique avec ses partenaires aussi bien au Maroc qu’à l’étranger.
        Notre mécanisme est de : Vous écoutez pour atteindre à votre souci et de
        vos besoins Nous vous conseillons à la lumière de notre retour
        d’expériences, notre savoir-faire et de notre expertise dans le domaine
        Nous vous faisons une proposition qui tient compte de vos besoins cadrés
        précédemment et des technologies qui s’adapte à votre contexte Nous vous
        livrons la solution retenue dans les meilleurs délais et nous veillons à
        sa mise en marche et nous vous assistons pour la prise en main de votre
        solution Votre Satisfaction Est Notre Capital
      </p>
      <div className="serviceCards">
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service} />
        ))}
      </div>
    </>
  );
};

export default SectionWrapper(About, "about");
