import React from "react";
import SectionWrapper from "../../scrollMotion/SectionWrapper";

import "./Work.css";

const Works = () => {
  return (
    <section className="mainWork">
      <div className="work">
        <div className="map">
          <iframe
            title="Embedded Map"
            width="100%"
            height="450"
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.9551575100763!2d-9.56673082535133!3d30.43737309984874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b780efc6fdd9%3A0xf7bd154c1cd1d250!2sFZ%20TECH%20SECURE!5e0!3m2!1sen!2sma!4v1707410785972!5m2!1sen!2sma"
          ></iframe>
        </div>

        <div className="houres">
          <h3>Heures de travail</h3>
          <div className="days">
            <p>Lundi</p>
            <p>08h30 - 18h00</p>
          </div>
          <div className="days">
            <p>Mardi</p>
            <p>08h30 - 18h00</p>
          </div>
          <div className="days">
            <p>Mercredi</p>
            <p>08h30 - 18h00</p>
          </div>
          <div className="days">
            <p>Jeudi</p>
            <p>08h30 - 18h00</p>
          </div>
          <div className="days">
            <p>Vendredi</p>
            <p>08h30 - 18h00</p>
          </div>
          <div className="days dayoff">
            <p>Samedi</p>
            <p>09h00 - 14h00</p>
          </div>
          <div className="days dayoff">
            <p>Dimanche</p>
            <p>--:-- - --:--</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWrapper(Works, "");
