import React from "react";
import "./WhatsApp.css";
import { whatsApp } from "../../assets";
function WhatsApp() {
  return (
    <div className="floating-whatsApp">
      <a
        href="https://wa.me/212600651985"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsApp} alt="whatsApp icon" />
      </a>
    </div>
  );
}

export default WhatsApp;
